import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: false,
    items: [
      // {
      //   label: 'Exchange',
      //   href: 'https://exchange.privacyswap.finance/#/swap?inputCurrency=BNB&outputCurrency=0x7762a14082ab475c06d3868b385e46ae27017231'
      // },
      // {
      //   label: 'Liquidity',
      //   href: 'https://exchange.privacyswap.finance/#/add/BNB/0x7762A14082Ab475C06D3868B385E46aE27017231'
      // }

      {
        label: 'Exchange',
        href: 'https://exchange.privacyswap.finance/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.privacyswap.finance/#/pool',
      },
    ],
  },


  {
    label: 'Swap Pydex',
    icon: 'TradeIcon',
    href: 'https://pydexswap.privacyswap.finance/',
  },

  // {
  //   label: 'Games',
  //   icon: 'NftIcon',
  //   items: [
  //     {
  //       label: 'Hack The Vaults',
  //       href: '/h4ck7h3v4u17',
  //     },
  //     // {
  //     //   label: 'GoldenWheel',
  //     //   href: '/tree',
  //     // }
   
  //   ]
  // },

  {
    label: 'Locked Pools',
    icon: 'MoonIcon',
    href: '/lockedPools',
  },
  {
    label: 'LP Creator',
    icon: 'NftIcon',
    href: '/lpCreator',
  },
  // {
  //   label: 'PrivacyPools (OLD)',
  //   icon: 'PoolIcon',
  //   href: 'https://old.privacyswap.finance/pools',
  // },

  {
    label: 'Referral',
    icon: 'FarmIcon',
    href: '/referrals',
  },

  {
    label: 'PrivacyFarms',
    icon: 'FarmIcon',
    href: 'https://app.privacyswap.finance/farms',
  },

 
  {
    label: 'PrivacyPools',
    icon: 'PoolIcon',
    href: 'https://app.privacyswap.finance/pools',
  },

  {
    label: 'PrivacyVaults',
    icon: 'LayerIcon',
    href: 'https://vaults.privacyswap.finance/',
  },

  {
    label: 'Privacy NFT',
    icon: 'LayerIcon',
    href: '/nft',
  },
  // {
  //   label: 'Presale',
  //   icon: 'LayerIcon',
  //   href: '/presale',
  // },

  {
    label: 'PRV Convertor ',
    icon: 'LayerIcon',
    href: '/converter',
  },
  
  {
    label: 'Privacy Card',
    icon: 'TicketIcon',
    href: 'https://cards.privacyswap.finance/',
  },


  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/goose-finance',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/goose-finance/',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  //     },
  //   ]
  // },

  {
    label: 'Price Charts',
    icon: 'InfoIcon',
    initialOpenState: false,
    items: [
     

      {
        label: 'Dex Guru (PRVG)',
        href: 'https://dex.guru/token/0x774AD968F8f3801739BCBc970F833C664d28511d-bsc',
      },
      {
        label: 'Poo Coin (PRVG)',
        href: 'https://poocoin.app/tokens/0x774AD968F8f3801739BCBc970F833C664d28511d',
      },
     
      {
        label: 'Dex Tools (PRVG)',
        href: 'https://www.dextools.io/app/bsc/pair-explorer/0x50cdbfd0a01949fcc3647fbcb40c4ef34ad6475f',
      },



      {
        label: 'Dex Guru (PRV2)',
        href: 'https://dex.guru/token/0xee10936e1f6b49945b17efeb106cc6992fe5ae47-bsc',
      },
      {
        label: 'Poo Coin (PRV2)',
        href: 'https://poocoin.app/tokens/0xee10936e1f6b49945b17efeb106cc6992fe5ae47',
      },
  
      {
        label: 'Dex Tools (PRV2)',
        href: 'https://www.dextools.io/app/bsc/pair-explorer/0x21f141d2927d2bc639603c8bc922571b88ef812c',
      },
    ],
  },
  

  {
    label: 'Audit',
    icon: 'AuditIcon',
    href: 'https://github.com/PrivacySwap/Smart-Contract-Audits/blob/main/PrivacySwap.pdf',
  },

  {
    label: 'How-To',
    icon: 'LanguageIcon',
    href: 'https://docs.privacyswap.finance',
  },

  {
    label: 'More',
    icon: 'MoreIcon',
    initialOpenState: false,

    items: [
      {
        label: 'Github',
        href: 'https://github.com/privacyswap/',
      },
      {
        label: 'Docs',
        href: 'https://docs.privacyswap.finance/',
      },
    ],
  },
]

export default config
